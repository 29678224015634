<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 justify-between overflow-auto">
      <ul
        class="w-5/12 overflow-auto p-8 bg-blue-100 text-black"
        aria-label="notes"
      >
        <li>
          keep your responses positive and upbeat. It’s best to deliver a
          negative message in person.
        </li>
        <li>
          keep them short. a good response is usually less than 5 seconds long.
        </li>
        <li>
          be careful with humor. it helps make a good conversation, but it can
          be easily misinterpreted, too.
        </li>
        <li>
          remember your ultimate goal here. consider ending your response by
          prompting the user to schedule a tour.
        </li>
        <li>
          don’t forget to make sure everything your digital human says is fully
          compliant with the Fair Housing Act!
        </li>
      </ul>
      <div class="form overflow-auto">
        <div class="form-row">
          <div class="form-col">
            <label for="categories">main topic</label>
            <text-input
              :value="this.category.intent_category_name"
              disabled
            />
          </div>
          <div class="form-col">
            <label for="categories">conversation detail</label>
            <text-input
              :value="this.intent.suggestion_text"
              disabled
            />
          </div>
          <div class="form-col">
            <label for="categories">next level detail</label>
            <text-input
              :value="this.subIntent.suggestion_text"
              disabled
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="responseText">custom response text</label>
            <text-input
              id="response_text"
              v-model="responseText"
              multiline
            />
            <span v-if="hasError('responseText')" class="form-error">{{
              showError('responseText')
            }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="spanish_response_text"
              >spanish custom translation (optional)</label
            >
            <text-input
              type="spanish_response_text"
              id="spanish_response_text"
              v-model="spanish_response_text"
              multiline
            />
          </div>
        </div>
        <div v-if="this.spanish_response_text">
          <div
            class="font-semibold font-inter p-4 bg-red-100 text-red-700 mb-4"
            v-if="this.spanish_response_text.length === 0"
          >
            Note: it may take up to a week for Quext to translate your custom
            response for you.
          </div>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="cancel" :secondary="save" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import TextInput from '@/components/ui/TextInput';

export default {
  name: 'CreateResponse',
  components: { Loader, ModalFooter, TextInput },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      category: {},
      intent: {},
      subIntent: {},
      responseText: '',
      spanish_response_text: '',
      loading: false,
      changedIntents: false,
      footer: {
        primaryButton: 'cancel',
        secondaryButton: 'save changes',
      },
    }
  },
  methods: {
    fetchResponse() {
      this.loading = true
      this.$dhDataProvider
        .getOne('response', { id: this.$route.params.id })
        .then((res) => {
          this.category = { intent_category_name: res.category_name }
          this.responseText = res.response_text
          this.spanish_response_text = res.spanish_response_text
          this.intent = {
            intent_name: res.intent_name,
            suggestion_text: res.suggestion_text,
          }
          this.subIntent = {
            intent_name: res.next_level,
            suggestion_text: res.next_level_suggestion_text,
          }
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => (this.loading = false))
    },
    cancel() {
      this.redirect('conversations_responses.index')
    },
    save() {
      this.responseText = this.responseText.trim()
      if (!this.community) {
        this.notifyError(
          'please select a community to continue, then refresh the browser'
        )
        return
      }
      if (this.validator.passes()) {
        this.loading = true
        this.$dhDataProvider
          .update('editResponse', {
            id: this.$route.params.id,
            data: {
              community_id: this.community?.id,
              intent_name:
                this.subIntent?.intent_name === 'general'
                  ? this.intent.intent_name
                  : this.subIntent?.intent_name,
              response_text: this.responseText,
              spanish_response_text: this.spanish_response_text ? this.spanish_response_text.trim() : null,
            },
          })
          .then(() => {
            this.notifySuccess('The response was updated successfully')
            this.redirect('conversations_responses.index')
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => (this.loading = false))
      }
    },
  },
  validations: {
    responseText: 'required',
  },
  created() {
    this.initValidator()
    this.fetchResponse()
  },
}
</script>
<style scoped>
.form {
  width: 55%;
}
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
</style>
